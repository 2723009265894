import React, { useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { Formik, Form, useField, FieldArray } from 'formik'
import * as Yup from 'yup'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Button } from '@vizeat/components/es6/components/Button'
import { Input } from '@vizeat/components/es6/components/Input'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
import { Alert } from '@vizeat/components/es6/components/Alert'
import { Text } from '@vizeat/components/es6/components/Text'
import { Divider } from '@vizeat/components/es6/components/Divider'
import IconCross from '@vizeat/components/es6/assets/icons/IconCross'
import { getCurrentUserEmail } from 'redux/selectors'
import { useNotifyCoguests } from 'hooks/queries/booking/useBookingMutation'
import { Select } from '@vizeat/components/es6/components/Select'
import { useQuery } from '@tanstack/react-query'
import { getDiets } from 'api/foods'

function CustomInput({ name, placeholder, type }) {
  const { t } = useTranslation()
  const [field, meta] = useField({ name, type })

  return <Input {...field} error={meta.touched && meta.error && t(meta.error)} type={type} placeholder={placeholder} />
}

CustomInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

function DietsInput({ name }) {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField({ name })
  const { data: diets = [] } = useQuery(['diets'], getDiets, {
    staleTime: 1000 * 60 * 60 * 24,
    select: useCallback((data) => {
      return data.map((item) => ({ value: item.id, label: item.title }))
    }, []),
  })

  function handleChange(options) {
    const diets = options.map(({ value }) => value)
    helpers.setValue(diets)
    helpers.setTouched()
  }

  return (
    <Select
      isMulti
      error={meta.touched && meta.error && t(meta.error)}
      value={field.value}
      onChange={handleChange}
      options={diets}
    />
  )
}

DietsInput.propTypes = {
  name: PropTypes.string.isRequired,
}

export function CoguestForm({ booking }) {
  const { t } = useTranslation()
  const { mutate, isLoading, isSuccess, isError } = useNotifyCoguests()

  const bookerEmail = useSelector(getCurrentUserEmail)

  const emptyCoguestPayload = {
    firstname: '',
    lastname: '',
    email: '',
    diets: [],
  }

  const coguestFormValidationSchema = Yup.object().shape({
    coguests: Yup.array().of(
      Yup.object().shape({
        firstname: Yup.string().required(__('FormField::Required field')),
        lastname: Yup.string().required(__('FormField::Required field')),
        diets: Yup.array().of(Yup.number()),
        email: Yup.string()
          .email(__('FormField::Wrong format'))
          .test({
            name: 'emailTest',
            message: __("CoguestForm::FormField::Guest's email should be different from the booker's"),
            test: (value) => value !== bookerEmail,
          }),
      }),
    ),
  })

  if (isSuccess) {
    return (
      <Alert type='success'>
        <Alert.Title>
          {booking.status === 'validated_by_host'
            ? t('CoguestForm::Emails sent!')
            : t(
                'CoguestForm::Your guests will receive a confirmation email once the host has accepted your booking request.',
              )}
        </Alert.Title>
      </Alert>
    )
  }

  return (
    <Flex flexDirection='column' my='16px'>
      <Text>{t('Booking::Who will be joining the table?')}</Text>
      <Text as='label' size='s' type='attention'>
        {t('Booking::Share the details of the experience with your friends.')}
      </Text>
      <Formik
        initialValues={{ coguests: [emptyCoguestPayload] }}
        onSubmit={({ coguests }) => mutate({ bookingId: booking.id, payload: coguests })}
        validationSchema={coguestFormValidationSchema}
      >
        {({ values: { coguests } }) => (
          <Form>
            <FieldArray name='coguests'>
              {(formikArrayFieldProps) => (
                <>
                  {coguests.map((_, index) => (
                    <Box key={index}>
                      <Flex flexDirection='row' justifyContent='space-between' alignItems='center' mt='16px'>
                        <Text size='s' as='span'>
                          {t('CoguestForm::Guest {{index}}', { index: index + 1 })}
                        </Text>
                        <Button type='button' clear onClick={() => formikArrayFieldProps.remove(index)}>
                          <IconCross color='mediumGray' />
                        </Button>
                      </Flex>
                      <Box>
                        <CustomInput
                          name={`coguests.${index}.firstname`}
                          type='text'
                          placeholder={t('CoguestForm::First Name')}
                        />
                        <CustomInput
                          name={`coguests.${index}.lastname`}
                          type='text'
                          placeholder={t('CoguestForm::Last Name')}
                        />
                        <CustomInput
                          name={`coguests.${index}.email`}
                          type='email'
                          placeholder={t('CoguestForm::Email')}
                        />
                        <DietsInput name={`coguests.${index}.diets`} />
                      </Box>
                      {index !== coguests.length - 1 && <Divider mt='32px' mb='16px' color='lightGray' />}
                    </Box>
                  ))}
                  {coguests.length <= booking.seats - 1 && (
                    <Box textAlign='right' mt='8px'>
                      <Button clear type='button' onClick={() => formikArrayFieldProps.push(emptyCoguestPayload)}>
                        {`+ ${t('Booking::Add a guest')}`}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </FieldArray>
            {coguests.length > 0 && (
              <Box mt='8px' textAlign='center'>
                <ButtonWithLoader clearPrimary loading={isLoading}>
                  {t('Booking::Send invite')}
                </ButtonWithLoader>
              </Box>
            )}
          </Form>
        )}
      </Formik>

      {isError && (
        <Box mt='8px'>
          <Text color='red'>{t('CoguestForm::There was a problem, please try again later.')}</Text>
        </Box>
      )}
    </Flex>
  )
}

CoguestForm.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seats: PropTypes.number.isRequired,
    conversation_id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}
